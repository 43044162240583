import { ref } from '@nuxtjs/composition-api'
import { useI18n } from 'nuxt-i18n-composable'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

export const LOCAL_STORAGE_LOCALE_KEY = 'locale'
const plDays = ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So']
const enDays = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
const plMonth = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
]
const plMonthShort = [
  'Sty',
  'Lut',
  'Mar',
  'Kwi',
  'Maj',
  'Cze',
  'Lip',
  'Sie',
  'Wrz',
  'Paź',
  'Lis',
  'Gru',
]
const enMonth = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const enMonthShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export enum LocaleName {
  PL = 'pl',
  EN = 'en',
}

export type Locale = `${LocaleName}`

const defaultLocale = LocaleName.PL

const supportedLocales: Locale[] = Object.values(LocaleName)

export const i18nLocale = ref<Locale>(defaultLocale)

export const translatePrimeVue = (locale: string) => {
  const vm = new Vue()
  // @ts-ignore
  vm.$primevue.config.locale.monthNames =
    locale === LocaleName.PL ? plMonth : enMonth
  // @ts-ignore
  vm.$primevue.config.locale.monthNamesShort =
    locale === LocaleName.PL ? plMonthShort : enMonthShort
  // @ts-ignore
  vm.$primevue.config.locale.dayNamesMin =
    locale === LocaleName.PL ? plDays : enDays
  // @ts-ignore
  vm.$primevue.config.locale.firstDayOfWeek = 1
}
export const useScopedI18n = (scope?: string) => {
  const i18nTools = useI18n()

  const getScopedKey = (key: string) => (scope ? `${scope}.${key}` : key)

  const ts = (key: string, choice?: number, values?: VueI18n.Values) =>
    i18nTools.tc(getScopedKey(key), choice, values)?.toString()

  const setLocale = (locale: Locale) => {
    i18nLocale.value = locale
    i18nTools.locale.value = locale
  }

  const changeLocale = (locale: Locale) => {
    translatePrimeVue(locale)
    localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale)
    setLocale(locale)
  }

  const setInitialLocale = () => {
    const retrievedLocale =
      localStorage.getItem(LOCAL_STORAGE_LOCALE_KEY) ||
      navigator.language.split('-')[0]

    function isLocaleSupported(locale: string): locale is Locale {
      return supportedLocales.includes(locale as Locale)
    }

    setLocale(
      isLocaleSupported(retrievedLocale) ? retrievedLocale : defaultLocale
    )
  }

  return {
    ts,
    ...i18nTools,
    changeLocale,
    setInitialLocale,
  }
}
