import 'cross-fetch/polyfill'
import { SentryLink } from 'apollo-link-sentry'
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  Observable,
  HttpLink,
} from '@apollo/client/core'
import type { ApolloCache, NormalizedCache } from '@apollo/client/core'
import { v4 as uuidv4 } from 'uuid'
import { Context } from '@nuxt/types'
import buildErrorLink from './errorLink'
import { currentPracticeId, userRole } from '~/composable/userData'
import { getAuthToken } from '~/utils/token'
import { UseContextReturn, logout, parseJwt } from '~/plugins/auth'

const GQL_URL =
  process.env.NUXT_ENV_HASURA_URL || 'http://localhost:8080/v1/graphql'

const httpLink = new HttpLink({
  uri: GQL_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

// Cache implementation
export const cache = new InMemoryCache(
  {}
) as unknown as ApolloCache<NormalizedCache>

const publicRoutes = [
  'MarkGDPRDocumentAsOpened',
  'GetGDPRDocument',
  'SignGDPRDocument',
]

export const validateJWTLink = (context: Context | UseContextReturn) =>
  new ApolloLink((operation, forward) => {
    if (publicRoutes.includes(operation.operationName)) {
      return forward(operation)
    }
    const jwtToken = getAuthToken(context.$auth)

    if (typeof jwtToken !== 'string') {
      logout(context)
      window.location.replace('/')
      return new Observable((subscriber) => {
        subscriber.error('Token does not exist')
      })
    }

    const { exp } = parseJwt(jwtToken)
    const currentTime = new Date().getTime()

    if (currentTime > exp * 1000) {
      logout(context)
      window.location.replace('/')
      return new Observable((subscriber) => {
        subscriber.error('Token expired')
      })
    }

    operation.setContext(() => ({
      headers: {
        Authorization: jwtToken,
      },
    }))

    return forward(operation)
  })

const authLink = new ApolloLink((operation, forward) => {
  const ctx = operation.getContext()

  operation.setContext(() => {
    const additionalHeaders: Record<string, string> = {
      'x-transaction-id': uuidv4(),
    }
    additionalHeaders['X-Hasura-Role'] = userRole.value

    if (currentPracticeId.value) {
      additionalHeaders['X-Hasura-Practice-Id'] = String(
        currentPracticeId.value
      )
    }
    return {
      headers: {
        ...ctx.headers,
        ...additionalHeaders,
      },
    }
  })

  return forward(operation)
})

// Create the apollo client
export const buildApolloClient = (context: Context | UseContextReturn) =>
  new ApolloClient({
    link: ApolloLink.from([
      validateJWTLink(context),
      authLink,
      new SentryLink({
        setTransaction: true,
        setFingerprint: true,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeError: true,
          includeContext: ['headers'],
        },
      }),
      buildErrorLink(context),
      httpLink,
    ]),
    cache,
    connectToDevTools: true,
  })
