import { onError } from '@apollo/client/link/error'
import { Context } from '@nuxt/types'
import { i18nLocale } from '~/composable/scopedI18n'
import { useToasts } from '~/composable/toasts'
import enTranslations from '~/lang/en/errors'
import plTranslations from '~/lang/pl/errors'
import { UseContextReturn, logout } from '~/plugins/auth'
import { isDev } from '~/utils/env'

// i18n only works when called in the setup function
const i18n: Record<string, Record<string, string>> = {
  en: enTranslations,
  pl: plTranslations,
}

const buildErrorLink = (context: Context | UseContextReturn) =>
  onError((err) => {
    const errorMessage =
      err.graphQLErrors?.map((e) => e.message).join('\n') ||
      err.networkError?.message

    const graphQLError = err.graphQLErrors?.[0]

    // 401 from hasura
    if (
      graphQLError?.extensions?.code === 'access-denied' ||
      graphQLError?.extensions?.code === 'unexpected'
    ) {
      window.location.replace('/')
      return
    }

    // 401 from django
    if (graphQLError?.message === 'access-denied') {
      logout(context)
      window.location.replace('/')
      return
    }

    const { showError } = useToasts()

    const getMessage = () => {
      if (errorMessage) return errorMessage

      if (!i18nLocale.value) return ''

      const translatedMessage =
        i18n[i18nLocale.value][err.operation?.operationName]

      return translatedMessage || i18n[i18nLocale.value].error
    }

    showError(getMessage())

    if (isDev()) {
      console.error(err)
    }
  })

export default buildErrorLink
